<div class="modal-form modal-grant" *ngIf="showModal" (click)="closeModal($event)">
  <div class="modal-form-dialog">
    <div class="modal-header border-0">
        <span aria-label="Zamknij" (click)="closeModal($event)">
          <span class="icon-x"></span>
        </span>
    </div>
    <div class="modal-grant-body">
      <div class="container px-3">
        <div class="row">
          <div class="col-12 modal-grant-body-headline">
            Jak uzyskać dotację?
          </div>
        </div>
      </div>
      <div class="container px-3">
        <div class="row">
          <div class="col-12 pb-3">
            <div class="modal-grant-box">
              <ul class="modal-grant-box-list">
                <li>
                  <strong>Krok 1</strong>
                  <br />
                  Podpisanie umowy, dokonanie opłaty wstępnej, podpisanie protokołu przekazania pojazdu do używania/używania i pobierania pożytków
                </li>
                <li>
                  <strong>Krok 2</strong>
                  <br />
                  Rejestracja pojazdu na Korzystającego/firmę leasingową/wynajmu oraz ubezpieczenie pojazdu polisą OC/AC
                </li>
                <li>
                  <strong>Krok 3</strong>
                  <br />
                  Wypełnienie formularza wniosku online
                </li>
                <li>
                  <strong>Krok 4</strong>
                  <br />
                  Otrzymanie decyzji i wypłata środków
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
