import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'bootstrap';
import {BrandService} from '../../services/brand.service';
import {Price} from '../../models/offer/price/price';

declare var $: any;
@Component({
  selector: 'app-grant-modal',
  templateUrl: './grant-modal.component.html',
})
export class GrantModalComponent implements OnInit {


  @Input() showModal = false;
  @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) {}

  ngOnInit() {}

  closeModal(e) {
    const target = $(e.target);
    if (target.hasClass('modal-grant') || target.hasClass('icon-x')) {
      $('body').removeClass('form-modal-open');
      this.showModal = false;
      this.showModalChange.emit(this.showModal);
    }
  }
}
