import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'bootstrap';

declare var $: any;
@Component({
  selector: 'app-insurance-sol-modal',
  templateUrl: './insurance-sol-modal.component.html',
})
export class InsuranceSolModalComponent implements OnInit {
  @Input() showModal = false;
  @Input() customerType = '';
  @Input() modalType = '';
  @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) {}

  ngOnInit() {}

  closeModal(e) {
    const target = $(e.target);
    if (target.hasClass('modal-insurance-sol') || target.hasClass('icon-x')) {
      $('body').removeClass('form-modal-open');
      this.showModal = false;
      this.showModalChange.emit(this.showModal);
    }
  }
}

