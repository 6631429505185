<div class="financing__head row align-items-end" [ngClass]="{'beam-top-name-logo' : calculation.provider === 'Stellantis Financial Services'}">
    <div class="financing__head-left col-6" [ngClass]="{'financing-calculator-leasing' : calculation.priceType === 'NET'}">
        <h4  class="financing__head-name text-uppercase" [innerHTML]="calculation.title"></h4>
    </div>
    <div class="financing__head-right col-6 text-right">
      <ng-container *ngIf="offer.brand.slug !== 'fiat' && offer.brand.slug !== 'fiat-professional'">
        <img *ngIf="calculation.provider === 'FCA Bank S.p.A.'" src="assets/img/logo_fcabank_white.png" alt="FCA BANK"/>
        <img *ngIf="calculation.provider === 'FCA Leasing Polska Sp. z o.o.'" src="assets/img/logo_fcaleasing_white.png" alt="FCA LEASING"/>
        <img *ngIf="calculation.provider === 'Leasys Polska Sp. z o.o.'" src="assets/img/logo_leasys_white.png" alt="LEASYS"/>
        <img *ngIf="calculation.provider === 'Stellantis Financial Services'" src="assets/img/logo_stellantis_financial_white.svg" alt="Stellantis Financial Services"/>
      </ng-container>
      <ng-container *ngIf="offer.brand.slug === 'fiat' || offer.brand.slug === 'fiat-professional'">
        <img *ngIf="calculation.provider === 'FCA Bank S.p.A.'" src="assets/img/logo-fcabank-black.png" alt="FCA BANK"/>
        <img *ngIf="calculation.provider === 'FCA Leasing Polska Sp. z o.o.'" src="assets/img/logo-fcaleasing-black.png" alt="FCA LEASING"/>
        <img *ngIf="calculation.provider === 'Leasys Polska Sp. z o.o.'" src="assets/img/logo-leasys-black.png" alt="LEASYS"/>
        <img *ngIf="calculation.provider === 'Stellantis Financial Services'" src="assets/img/logo_stellantis_financial_blue.svg" alt="Stellantis Financial Services"/>
      </ng-container>
    </div>
</div>
<div class="financing__summary row" *ngIf="correctInstallment">
  <ng-container *ngIf="offer.brand.slug === 'fiat'">
    <div *ngIf="offer.brand.slug === 'fiat'" class="financing__summary-label col-6 col-xl-7 text-center text-uppercase">
      <ng-container *ngIf="calculation.priceType === selectedPriceType">
        RATA <strong>{{calculation.installment | formatMoney}}</strong> ZŁ
      </ng-container>

      <ng-container *ngIf="calculation.priceType !== selectedPriceType">
        <ng-container *ngIf="selectedPriceType === 'NET'">
          RATA <strong>{{Math.ceil(calculation.installment) / 1.23 | formatMoney}}</strong> ZŁ
        </ng-container>
        <ng-container *ngIf="selectedPriceType === 'GROSS'">
          RATA <strong>{{Math.ceil(calculation.installment) * 1.23 | formatMoney}}</strong> ZŁ
        </ng-container>
      </ng-container>
    </div>
    <div class="financing__summary-switch">
      <div class="switch-btn"
           [ngClass]="{'switch-btn-active': selectedPriceType === 'GROSS'}"
           (click)="changeSelectedPriceType('GROSS')">brutto</div>
      <div class="switch-box"
           [ngClass]="{'switch-net': selectedPriceType === 'NET'}"
           (click)="switchSelectedPriceType()"></div>
      <div class="switch-btn"
           [ngClass]="{'switch-btn-active': selectedPriceType === 'NET'}"
           (click)="changeSelectedPriceType('NET')">netto</div>
    </div>
  </ng-container>

  <ng-container *ngIf="offer.brand.slug !== 'fiat'">
    <div class="financing__summary-label col-7 text-center text-uppercase">
      RATA <strong>{{calculation.installment | formatMoney}}</strong> ZŁ {{ calculation.priceType === 'NET' ? 'netto' : 'brutto'}}
    </div>
  </ng-container>
</div>
<div class="installment-alert" *ngIf="!correctInstallment">
  Parametry kalkulacji tego produktu nie obejmują poniższych ustawień
</div>
<div class="financing__slider-wrap pt-3 pb-3 px-5">
  <div class="financing__slider" *ngFor="let slider of sliders">
    <div *ngIf="slider === 'contribution'" class="financing__slider-label text-uppercase">
      {{ calculation.currentFields[slider].description.replace(' 0 zł netto', ' 1 zł netto').replace(' 0 zł brutto', ' 1,23 zł brutto') }}
      <span *ngIf="grantValue > 0"><br /><small>(w tym {{grantValue | formatMoney}} zł dotacji)</small></span>
    </div>
    <div *ngIf="slider !== 'contribution'" class="financing__slider-label text-uppercase">
      {{ calculation.currentFields[slider].description }}
    </div>
    <mat-slider
      thumbLabel
      [displayWith]="getSliderLabel.bind({label: calculation.currentFields[slider].label}, slider)"
      min="0"
      max="{{ calculation.availableFields[slider].length - 1 }}"
      step="1"
      [(value)]="sliderValues[slider]"
      [disabled]="calculation.availableFields[slider].length === 1"
      (input)="sliderChange($event, slider)"
      (change)="triggerAdobeDTMEvent()"
    ></mat-slider>
  </div>

  <div class="financing__slider extra-services" *ngIf="calculation.clientType === 'b2c' && availableExtraServices.length > 0">
    <div class="financing__slider-label text-uppercase">
      Elastyczny termin zakończenia umowy:
    </div>
    <div class="extra-services-buttons">
      <div (click)="setExtraServices('brak')" class="extra-services-button"  [ngClass]="{'checked': currentExtraServices.length === 0}">BRAK</div>
      <ng-container *ngFor="let service of availableExtraServices">
        <div class="extra-services-button" (click)="setExtraServices(service.id)" [ngClass]="{'checked': service.id === currentExtraServices[0]}">{{service.name}}
          <span class="icon-info" (click)="showExtraServicesInfo($event)"></span>
        </div>
      </ng-container>
    </div>
  </div>


</div>
<div *ngIf="addToCartBtn" class="addToCartBtn px-5">
  <button *ngIf="correctInstallment" class="btn w-100" (click)="currentValue()">DODAJ DO KOSZYKA</button>
  <button *ngIf="!correctInstallment" class="btn financing-btn-disabled w-100">DODAJ DO KOSZYKA</button>
</div>
<div *ngIf="isCalculatorTest && calculation.calculationParams" class="more-calculator-params">
  <p><span>Parametry przyjęte do kalkulacji:</span><p>
  <p>
    <span>Cena katalogowa netto: {{ calculation.calculationParams.baseNetPrice !== null ? (calculation.calculationParams.baseNetPrice | formatMoney) + ' zł' : '-----' }}</span>
    <span>Cena katalogowa brutto: {{ calculation.calculationParams.baseGrossPrice !== null ? (calculation.calculationParams.baseGrossPrice | formatMoney) + ' zł' : '-----' }}</span>
    <span>Cena po rabatach netto: {{ calculation.calculationParams.netPrice !== null ? (calculation.calculationParams.netPrice | formatMoney) + ' zł' : '-----' }}</span>
    <span>Cena po rabatach brutto: {{ calculation.calculationParams.grossPrice !== null ? (calculation.calculationParams.grossPrice | formatMoney) + ' zł' : '-----' }}</span>
    <span>Oprocentowanie: {{ calculation.calculationParams.rate !== null ? calculation.calculationParams.rate + '%' : '-----' }}</span>
    <span>Subwencja: {{ calculation.calculationParams.subsidy !== null ? calculation.calculationParams.subsidy + '%' : '-----' }}</span>
    <span>RV: {{ calculation.calculationParams.rv !== null ? calculation.calculationParams.rv + '%' : '-----' }}</span>
    <span>Opłata przygotowawcza: {{ calculation.calculationParams.preparationFee !== null ? calculation.calculationParams.preparationFee + '%' : '-----' }}</span>
    <span>Opłata miesięczna: {{ calculation.calculationParams.monthlyManagementFee !== null ? calculation.calculationParams.monthlyManagementFee + ' zł' : '-----' }}</span>
    <span>Kwota finansowana netto: {{ calculation.calculationParams.netCreditAmount !== null ? calculation.calculationParams.netCreditAmount + ' zł' : '-----' }}</span>
    <span>Kwota finansowana brutto: {{ calculation.calculationParams.grossCreditAmount !== null ? calculation.calculationParams.grossCreditAmount + ' zł' : '-----' }}</span>
    <span *ngIf="calculation.currentFields.contribution !== null">{{ calculation.currentFields.contribution.description.replace('Wkład własny', 'Wkład własny:') }}</span>
    <span>Produkt SOL: {{ calculation.psaProduct !== null ? calculation.psaProduct : '-----' }}</span>
    <span>Subprodukt SOL: {{ calculation.psaSubProduct !== null ? calculation.psaSubProduct : '-----' }}</span>
    <span>Kod LCDV: {{ calculation.vehicleLCDV !== null ? calculation.vehicleLCDV : '-----' }}</span>
  </p>
</div>
<div class="hidden-box-note" [ngClass]="{'show': showNote}">
  <div class="financing__note">
    <p [innerHTML]="calculation.note"></p>
  </div>
  <div class="link link--more" *ngIf="!showNote" (click)="showNote = true">Pokaż więcej <span class="icon icon-chevron-thin-right"></span></div>
  <div class="link link--less" *ngIf="showNote" (click)="showNote = false">Zwiń <span class="icon icon-chevron-thin-right"></span></div>
</div>
<app-flex-modal
  *ngIf="offer && offer.price"
  [(showModal)]="showModalFlex"
  [price]="offer.price"
></app-flex-modal>
<div *ngIf="error" class="financing__error">
  <loader [visible]="true"></loader>
</div>
