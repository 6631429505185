<div class="modal-form modal-insurance-sol" *ngIf="showModal" (click)="closeModal($event)">
  <div class="modal-form-dialog">
    <div class="modal-header border-0">
        <span aria-label="Zamknij" (click)="closeModal($event)">
          <span class="icon-x"></span>
        </span>
    </div>
    <div *ngIf="modalType === 'insuranceInfo'" class="modal-insurance-sol-body">
      <div class="container px-3">
        <div class="row">
          <div class="col-12 modal-insurance-sol-body-headline">
            Pakiet dealerski
          </div>
        </div>
      </div>
      <div class="container px-3">
        <div class="row">
          <div class="col-12 pb-3">
            <div class="modal-insurance-sol-box">
              <ul class="modal-insurance-sol-box-list pt-3">
                <li>
<!--                  <strong>Krok 1 {{customerType}} - {{modalType}}</strong>-->
                  Idealnie dopasowane pakiety OC/AC/NNW/Assistance renomowanych Zakładów Ubezpieczeń - STU ERGO Hestia S.A., TUiR Allianz Polska S.A., PZU S.A. lub TUiR WARTA S.A.
                </li>
                <li>
                  Zakres ubezpieczenia obejmuje m.in: brak udziałów własnych w szkodzie, utrzymanie sumy ubezpieczenia dla pojazdu fabrycznie nowego, w przypadku szkody gwarancja naprawy w Autoryzowanej Stacji Obsługi z użyciem oryginalnych i nowych części zamiennych bez amortyzacji, zgodnie z technologią producenta samochodu.
                </li>
                <li>
                  W zależności od wybranego Zakładu Ubezpieczeń możliwość dokupienia dodatkowych opcji tj.: ubezpieczenie szyb, ochrona zniżek, ubezpieczenie bagażu, powłok ceramicznych, opon, ubezpieczenie zdrowotne i inne.
                </li>
                <li>
                  Możliwość włączenia składki w raty finansowania.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="modalType === 'insuranceGap'" class="modal-insurance-sol-body">
      <div class="container px-3">
        <div class="row">
          <div class="col-12 modal-insurance-sol-body-headline">
            Ubezpieczenie Straty Finansowej GAP
          </div>
        </div>
      </div>
      <div class="container px-3">
        <div class="row">
          <div class="col-12 pb-3">
            <div class="modal-insurance-sol-box p-3">
              <p>
                Przedmiotem ubezpieczenia jest ryzyko poniesienia straty finansowej w odniesieniu do finansowanego pojazdu.
                <br />
                <br />
                W przypadku wystąpienia szkody całkowitej lub kradzieżowej pojazdu następuje wypłata:
              </p>
              <ul class="modal-insurance-sol-box-list">
                <li>
                  świadczenia ubezpieczeniowego w wysokości 25% wartości rynkowej pojazdu;
                </li>
                <li>
                  4% ceny nowego pojazdu w przypadku zawarcia kolejnej umowy finansowania w Stellantis Financial Services.
                </li>
              </ul>
              <p>
                Istnieje również możliwość zawarcia ubezpieczenia GAP w wariancie fakturowym – <strong>Super GAP</strong> lub <strong>GAP 15%</strong>.
                <br />
                <br />
                GAP fakturowy pokrywa stratę finansową w wysokości różnicy pomiędzy wartością fakturową finansowanego pojazdu a odszkodowaniem wypłaconym przez ubezpieczyciela autocasco lub ubezpieczyciela osoby trzeciej (sprawcy).
                <br />
                <br />
                Każdy z powyższych wariantów zapewnia ochronę już w pierwszym roku eksploatacji finansowanego pojazdu a maksymalna wysokość świadczenia to 250.000 zł.
                <br />
                <br />
                Zapytaj Dealera o szczegóły ubezpieczenia Stellantis Insurance Europe Ltd. i wybierz najlepszy dla siebie wariant.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="modalType === 'insuranceCpi'" class="modal-insurance-sol-body">
      <div class="container px-3">
        <div class="row">
          <div class="col-12 modal-insurance-sol-body-headline">
            Ubezpieczenie Spłaty Finansowania CPI
          </div>
        </div>
      </div>
      <div class="container px-3">
        <div class="row">
          <div class="col-12 pb-3">
            <div *ngIf="customerType === 'b2c' || customerType === 'p0p'" class="modal-insurance-sol-box p-3">
              <p>
                <strong>Bezpieczna Spłata Standard</strong>
                <br />
                <br />
                Przedmiotem ubezpieczenia jest zdrowie, życie i źródło dochodu Ubezpieczonego.
                <br />
                Ubezpieczenie chroni przed brakiem możliwości spłaty zobowiązania w odniesieniu do finansowanego pojazdu.
                <br />
                <br />
                W przypadku wystąpienia trwałej niezdolności do pracy lub śmierci Ubezpieczonego następuje wypłata świadczenia ubezpieczeniowego w wysokości pozostałej do zapłaty kwoty finansowania wynikającej z umowy finansowania, nie więcej niż 250.000 zł.
              </p>
              <p>
                Istnieje również możliwość zawarcia ubezpieczenia w wariancie  <br />
                <strong>Bezpieczna Spłata Premium.</strong>
                <br />
                <br />
                W tym wariancie, w przypadku wystąpienia trwałej niezdolności do pracy lub śmierci Ubezpieczonego następuje wypłata świadczenia ubezpieczeniowego w wysokości całkowitej początkowej kwoty finansowania wskazanej w umowie. Gwarantuje on także dodatkowy zakres ochrony na okoliczność czasowej niezdolności do pracy, utraty pracy oraz wydarzeń życiowych.
                <br />
                <br />
                Ubezpieczenie Spłaty Finansowania Bezpieczna Spłata jest ofertowane we współpracy ze Stellantis Life Insurance Europe Ltd. i Stellantis Insurance Europe Ltd.
                <br />
                <br />
                Zapytaj Dealera o szczegóły ubezpieczenia i wybierz najlepszy dla siebie wariant.
              </p>
            </div>
            <div *ngIf="customerType === 'b2b' || customerType === 'l101'" class="modal-insurance-sol-box p-3">
              <p>
                <strong>Bezpieczna Spłata Standard</strong>
                <br />
                <br />
                Przedmiotem ubezpieczenia jest zdrowie, życie i źródło dochodu Ubezpieczonego.
                <br />
                Ubezpieczenie chroni przed brakiem możliwości spłaty zobowiązania w odniesieniu do finansowanego pojazdu.
                <br />
                <br />
                W przypadku wystąpienia trwałej niezdolności do pracy lub śmierci Ubezpieczonego następuje wypłata świadczenia ubezpieczeniowego w wysokości pozostałej do zapłaty kwoty finansowania wynikającej z umowy finansowania, nie więcej niż 250.000 zł.
                <br />
                <br />
                Ubezpieczenie Spłaty Finansowania Bezpieczna Spłata jest ofertowane we współpracy ze Stellantis Life Insurance Europe Ltd.
                <br />
                <br />
                Zapytaj Dealera o szczegóły ubezpieczenia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
