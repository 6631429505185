import {Component, Input, OnInit} from '@angular/core';
import {BasicOffer} from '../../models/offer/basic-offer';
import {ObservedOfferService} from '../../services/observed-offer.service';
import {BrandService} from '../../services/brand.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-observed-offer-card',
  templateUrl: './observed-offer-card.component.html',
})
export class ObservedOfferCardComponent implements OnInit {

  @Input() item: BasicOffer;
  @Input() isOffersLoaded: boolean;
  @Input() isActive: boolean;
  brandName: string;
  brandSlug: string;
  additionalProtection = false;

  constructor(
      public observedOffers: ObservedOfferService,
  ) {
  }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    this.brandName = BrandService.getCurrentBrand().name;
    this.brandSlug = BrandService.getCurrentBrand().slug;

    if (this.item.version.toLowerCase() === 'electric vehicle') {
      this.item.version = null;
    }

    if (BrandService.getCurrentBrand().code === '83' && this.item.price.leasing !== undefined) {
      AppComponent.addOfferPriceFinancialProductNotes(this.item.price.leasing.note);
    }

    // if (this.brandSlug === 'alfa-romeo' && this.item.labels.indexOf('FCASTOCK3-650-PROMO') !== -1) {
    //   this.additionalProtection = true;
    // } else {
    //   this.additionalProtection = false;
    // }
  }
}
